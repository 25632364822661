<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            <b-button class="main-btn rounded me-2" @click="goToCompetentAuthority">
                <b-icon icon="arrow-left"></b-icon
            ></b-button>
            {{ $t("competent-authority.title") }}
        </h6>
        <b-row class="mb-5">
            <b-col>
                <b-card
                    class="shadow-sm border-0 rounded-0 ps-xl-4"
                    bg-variant="light"
                    no-header
                >
                    <b-card-text>
                        <b-row class="text-start my-2">
                            <b-col xl="6" md="9">
                                <b-row class="mb-2">
                                    <b-col sm="4">
                                        <label class="details-title">{{
                                            $t("competent-authority.name")
                                        }}</label>
                                    </b-col>
                                    <b-col>
                                        <span class="details-title-value">{{
                                            competentAuthority.name
                                        }}</span>
                                    </b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="4">
                                        <label class="details-title">{{
                                            $t("general.creation-date")
                                        }}</label>
                                    </b-col>
                                    <b-col>
                                        <span class="details-title-value">{{
                                            competentAuthority.creationDate
                                        }}</span>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
                <CompetentAuthorityOfficials :id="id" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import moment from "moment";
import CompetentAuthorityOfficials from "./CompetentAuthorityOfficials.vue";

export default {
    name: "competent-authority",
    components: {
        CompetentAuthorityOfficials,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            competentAuthority: {},
        };
    },
    async mounted() {
        await this.retrieveCompetentAuthority();
    },
    methods: {
        async retrieveCompetentAuthority() {
            this.loading = true;
            try {
                let response = await RepositoryFactory.competentAuthority.get({
                    id: this.id,
                });
                let competentAuthority = response.data;

                competentAuthority.creationDate = this.parseAndConvertDate(
                    competentAuthority.creationDate
                );

                this.competentAuthority = competentAuthority;
            } catch (error) {
                window.console.log(error);
            }
            this.loading = false;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        goToCompetentAuthority() {
            this.$router.push({
                name: "competent-authorities",
            });
        },
    },
};
</script>